import * as React from 'react';
import * as ReactDOM from 'react-dom';
import axios from 'axios';

const ImgUpload =({
    onChange,
    src
  })=>
    <label htmlFor="photo-upload" className="custom-file-upload fas">
      <div className="img-wrap img-upload" >
        <img htmlFor="photo-upload" src={src}/>
      </div>
      <input id="photo-upload" type="file" onChange={onChange}/> 
    </label>
  
  
  const Name =({
    onChange,
    value
  })=>
    <div className="field">
      <label htmlFor="name">
        name:
      </label>
      <input 
        id="name" 
        type="text" 
        onChange={onChange} 
        maxLength="25" 
        value={value} 
        placeholder="Alexa" 
        required/>
    </div>
  
  
  const Profile =({
    onSubmit,
    src,
    name,
    status,
  })=>
    <div className="card">
      <form onSubmit={onSubmit}>
        <h1>Profile Card</h1>
        <label className="custom-file-upload fas">
          <div className="img-wrap" >
            <img htmlFor="photo-upload" src={src}/>
          </div>
        </label>
        <div className="name">{name}</div>
        <button type="submit" className="edit"><i className="bi bi-wallet"></i> Edit Profile </button>
      </form>
    </div>


  const Edit =({
    onSubmit,
    children,
  })=>
    <div className="card">
      <form onSubmit={onSubmit}>
        <h1>Profile Card</h1>
          {children}
        <button type="submit" className="save"><i className="bi bi-wallet"></i> Save </button>
      </form>
    </div>
  
  class CardProfile extends React.Component {
    state = {
      file: '',
      imagePreviewUrl: 'https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true',
      name:'',
      active: 'edit'
    }
  
    photoUpload = e =>{
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      }
      reader.readAsDataURL(file);
    }
    editName = e =>{
      const name = e.target.value;
      this.setState({
        name,
      });
    }
    
    editStatus = e => {
      const status = e.target.value;
      this.setState({
        status,
      });
    }
    
    handleSubmit= e =>{
      e.preventDefault();
      let activeP = this.state.active === 'edit' ? 'profile' : 'edit';
      this.setState({
        active: activeP,
      })
      this.submitForm();
    }

    submitForm = () => {
      const formData = new FormData();
      formData.append("name", this.state.name);
      formData.append("photo-upload", this.state.file);
    
      //Post to current URL
      axios
        .post('/profile', formData)
        .then((res) => {
          alert("File Upload success");
        })
        .catch((err) => alert("File Upload Error"));
    };
    
    render() {
      const {
        imagePreviewUrl, 
        name,
        status,
        active
      } = this.state;
      return (
        <div>
          {(active === 'edit')?(
            <Edit onSubmit={this.handleSubmit}>
              <ImgUpload onChange={this.photoUpload} src={imagePreviewUrl}/>
              <Name onChange={this.editName} value={name}/>
            </Edit>
          ):(
            <Profile 
              onSubmit={this.handleSubmit} 
              src={imagePreviewUrl} 
              name={name}/>)}
          
        </div>
      )
    }
  }

  /**
 * Main entry point.
 */
 window.addEventListener('load', async () => {
  const container = document.getElementById('profilecard');
  if (container) ReactDOM.render(<CardProfile />, container);
});