import Web3Modal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import * as evmChains from 'evm-chains';
import * as axios from 'axios';

const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, 
    options: {
      appName: "web3",
      infuraId: "8b02f76d607fc1327f690e5e536eb78a" 
    }
  },
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId: "8b02f76d607fc1327f690e5e536eb78a",
    }
  },
  // "custom-metamask": {
  //   display: {
  //     logo: "/dist/images/metamask.svg",
  //     name: "MetaMask",
  //     description: "Connect to your MetaMask Wallet"
  //   },
  //   package: true,
  //   connector: async () => {
  //       let provider = null;
  //       if (typeof window.ethereum !== 'undefined') {
  //         let providers = window.ethereum.providers;
  //         provider = providers.find(p => p.isMetaMask); // <-- LOOK HERE
  //         try {
  //             await provider.request({ method: 'eth_requestAccounts' });
  //         } catch (error) {
  //             throw new Error("User Rejected");
  //         }
  //       } else {
  //           throw new Error("No MetaMask Wallet found");
  //       }
  //       console.log("MetaMask provider", provider);
  //       return provider;
  //   }
  // }
};

// Web3modal instance
let web3Modal

// Chosen wallet provider given by the dialog window
let provider;

// Address of the selected account
let selectedAccount;

function init() {
  try {
    web3Modal = new Web3Modal({
      cacheProvider: true,
      // disableInjectedProvider: true,
      providerOptions
    });
    if($( "#userProfile" ).is(":visible")){
      walletConnect();
    }
  }
  catch (e) {
    console.log('We have a error', e);
  }
}

/**
 * Kick in the UI action after Web3modal dialog has chosen a provider
 */
 async function fetchAccountData() {

  // Get a Web3 instance for the wallet
  const web3 = new Web3(provider);

  console.log("Web3 instance is", web3);
  console.log("Provider is", provider);

  // Get connected chain id from Ethereum node
  const chainId = await web3.eth.getChainId();
  // Load chain information over an HTTP API
  const chainData = await evmChains.getChain(chainId);

  // Get list of accounts of the connected wallet
  const accounts = await web3.eth.getAccounts();

  // MetaMask does not give you all accounts, only the selected account
  console.log("Got accounts", accounts);
  selectedAccount = accounts[0].toLowerCase();
  
  //Login
  web3Login();

  // Update UI
  if ($('#network-name').length) document.getElementById("network-name").textContent = chainData.name;
  if ($('#selected-account').length) document.getElementById("selected-account").textContent = selectedAccount;

  if ($('#template-balance').length) 
  {
    const template = document.getElementById("template-balance");
    const accountContainer = document.getElementById("accounts");
    // Purge UI elements any previously loaded accounts
    accountContainer.innerHTML = '';
    // Go through all accounts and get their ETH balance
    const rowResolvers = accounts.map(async (address) => {
      const balance = await web3.eth.getBalance(address);
      // ethBalance is a BigNumber instance
      // https://github.com/indutny/bn.js/
      const ethBalance = web3.utils.fromWei(balance, "ether");
      const humanFriendlyBalance = parseFloat(ethBalance).toFixed(4);
      // Fill in the templated row and put in the document
      const clone = template.content.cloneNode(true);
      clone.querySelector(".address").textContent = address;
      clone.querySelector(".balance").textContent = humanFriendlyBalance;
      accountContainer.appendChild(clone);
    });
    // Because rendering account does its own RPC commucation
    // with Ethereum node, we do not want to display any results
    // until data for all accounts is loaded
    await Promise.all(rowResolvers);
  }
}

/**
 * Fetch account data for UI when
 * - User switches accounts in wallet
 * - User switches networks in wallet
 * - User connects wallet initially
 */
async function refreshAccountData() {
  // Disable button while UI is loading.
  // fetchAccountData() will take a while as it communicates
  // with Ethereum node via JSON-RPC and loads chain data
  // over an API call.
  document.getElementById("wallet-connect").setAttribute("disabled", "disabled");
  await fetchAccountData(provider);
  document.getElementById("wallet-connect").removeAttribute("disabled");
  $( "#userWallet" ).hide();
  $( "#userProfile" ).show();
}

/**
 * web3 paswordless application login
 */
 async function web3Login() {
  const web3 = new Web3(provider);
  const address = selectedAccount;
  if (!address | address == null) {
    console.log('Null wallet address...');
    return;
  }
 
  console.debug('Login signature request starting...');
 
  axios.post(
    "/login",
    {
      address: address
    }
  )
  .then(function(response) {
    if (response.data.substring(0, 5) != "Error") {
      let message = response.data;
      let publicAddress = address;
 
      if (message == "Extend") {
        handleRefresh(publicAddress);
      } else {
        handleSignMessage(message, publicAddress).then(handleAuthenticate);
      }

      function handleSignMessage(message, publicAddress) {
        return new Promise((resolve, reject) =>
          web3.eth.personal.sign(
            web3.utils.utf8ToHex(message),
            publicAddress,
            (err, signature) => {
              console.debug('' + err);
              return resolve({ publicAddress, signature });
            }
          )
        );
      }
 
      function handleAuthenticate({ publicAddress, signature }) {
 
        try {

            console.log('Login sign request accepted...', arguments[0]);
 
            if (!arguments[0].signature){throw "Authentication cancelled, invalid signature"; }
            if (!arguments[0].publicAddress){throw "Authentication cancelled, invalid address"; }
 
            axios
              .post(
                "/auth",
                {
                  address: arguments[0].publicAddress,
                  signature: arguments[0].signature
                }
              )
              .then(function(response) {
                console.log(response.data[0]);
                if (response.data[0] == "Success") {
                  console.debug('Web3 Login sign request authenticated.');
                }
                $.ajaxSetup({
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer '+response.data[2]);
                    }
                });
                // window.location.href = '/profile';
              })
              .catch(function(error) {
                console.error(error);
                // walletDisconnect();
              });
 
          } catch(err) {
              console.error(err);
              walletDisconnect();
          }
      }

      function handleRefresh(publicAddress) {
 
        try {
            axios
              .post(
                "/check",
                {
                  address: publicAddress
                }
              )
              .then(function(response) {
                console.log(response.data[0]);
                if (response.data[0] == "Success") {
                  console.debug('Web3 Token renewed.');
                }
                $.ajaxSetup({
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer '+response.data[2]);
                    }
                });
              })
              .catch(function(error) {
                console.error(error);
              });
 
          } catch(err) {
              console.error(err);
          }
      }
 
    }
    else {
      console.debug("Error: " + response.data);
    }
 
  })
  .catch(function(error) {
    // console.error(error);
  });
}

/**
 * Connect wallet button pressed.
 */
 export async function walletConnect() {
  console.log("Opening a dialog", web3Modal);
  try {
    provider = await web3Modal.connect();
  } catch(e) {
    console.log("Could not get a wallet connection", e);
    if (e == "Error: User Rejected") {
      alert("Error connecting");
    }
    return;
  }

  // Subscribe to accounts change
  provider.on("accountsChanged", (accounts) => {
    fetchAccountData();
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId) => {
    fetchAccountData();
  });

  // Subscribe to networkId change
  provider.on("networkChanged", (networkId) => {
    fetchAccountData();
  });

  await refreshAccountData();
}

/**
 * Disconnect wallet button pressed.
 */
 async function walletDisconnect() {

  console.log("Killing the wallet connection", provider);

  // TODO: Which providers have close method?
  if(provider && provider.close) {
    await provider.close();
    await web3Modal.clearCachedProvider();
    provider = null;
  }

  selectedAccount = null;
  localStorage.clear();

  $( "#userProfile" ).hide();
  $( "#userWallet" ).show();

  axios.get("/logout")
  .then(function (response) {
    window.location.href = '/login';
  });
}

/**
 * Main entry point.
 */
window.addEventListener('load', async () => {
  init();
  document.getElementById("wallet-connect").addEventListener("click", walletConnect);
  document.getElementById("wallet-disconnect").addEventListener("click", walletDisconnect);
});