import 'jquery';
import 'web3modal';

//Web3
import {walletConnect} from './js/web3.js';

//Share
//

//Profile
import './js/profile.js';

/**
 * Main entry point.
 */
window.walletConnect = walletConnect;
// window.mint = mint;

import 'popper.js';
import 'bootstrap';
import 'bootstrap-colorpicker';
import 'bootstrap-select';

import './scss/app.scss';